<!-- pageName -->
<template>
  <div class="wrapper">
    <ul class="ul_icons ul_bg">
      <li class="cloud_platform" v-for="item in blocks" :key="item.id" @click="toUrl(item.tzurl)">
        <a>
          <div class="div_img">
            <img :src="item.bgimg" alt="" />
            <img :src="item.imgbz" alt="" />
          </div>
        </a>
        <span
          ><p>{{ item.title }}</p>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    toUrl(url) {
      window.open(url)
    },
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
.cloud_platform{
  cursor: pointer;
}
</style>
